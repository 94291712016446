import React, {useState} from 'react'
import Footer from '../components/Footer'
import { kotikaynnit} from '../components/kotikaynnit/Data'
import Navbar from '../components/NavbarSubPage'
import KotikaynnitSection from '../components/kotikaynnit'

const KotiK = () => {

    const [isOpen,setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle}/>
    <KotikaynnitSection  {...kotikaynnit}/>
    <Footer/>
  
    </>
  )
}

export default KotiK