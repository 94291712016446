import React,{useState,useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav,NavbarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink} from './NavbarElements'
import {animateScroll as scroll} from 'react-scroll'; 
import { Link as LinkR } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


const Navbar = ({toggle}) => {
    const [scrollNav,setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect (() => {
        window.addEventListener('scroll',changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }
  return (
   <>
   <Nav scrollNav={scrollNav}>
    <NavbarContainer>
    <NavLogo to='/' onClick={toggleHome}>
      <FaArrowLeft /> Takaisin pääsivulle
      </NavLogo>
    </NavbarContainer>
   </Nav>
   </>
  )
}

export default Navbar
