import React, { useState, useEffect } from 'react';

import {  Column1, Column2, Heading, ZoomableContainer, ZoomableImage, KylmahoitoContainter, KylmahoitoRow,KylmahoitoRowWrapper, KylmahoitoWrapper, Subtitle, TextWrapper, TopLine } from './kylmahoitoElements'
import theme from '../theme/theme';


const KylmahoitoSection = ({ bgcolor,bgcolorMobile,dark,darkMobile, id, imgStart, topLine, headLine, description, img, alt, imgWidth, imgHeight,lightText }) => {

  

  const [screenWidth, setScreenWidth] = useState(window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth);
  const [textWidth, setTextWidth] = useState(`${screenWidth - (screenWidth * imgWidth)}px`);
  const [imageWidth, setImageWidth] = useState(`${screenWidth * imgWidth}px`);

  useEffect(() => {

    window.scrollTo(0, 0);

  const handleResize = () => {
    const newScreenWidth = window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth;
    setScreenWidth(newScreenWidth);
    setTextWidth(`${newScreenWidth - (newScreenWidth * imgWidth)}px`);
    setImageWidth(`${newScreenWidth * imgWidth}px`);
  };

 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <KylmahoitoContainter bgcolor={bgcolor} bgcolorMobile={bgcolorMobile} id={id} screenWidth={screenWidth}>
        <KylmahoitoWrapper screenWidth={screenWidth}>
          <KylmahoitoRowWrapper bgcolor={bgcolor} bgcolorMobile={bgcolorMobile}>
            <KylmahoitoRow imgStart={imgStart}>
              <Column1 imgWidth={imgWidth}>
                <TextWrapper imgWidth={imgWidth} textWidth={textWidth}>
                  <TopLine>{topLine}</TopLine>
                  <Heading  dark={dark} darkMobile={darkMobile}>{headLine}</Heading>
                  <Subtitle dark={dark} darkMobile={darkMobile} lightText={lightText}>{description}</Subtitle>
                </TextWrapper>
              </Column1>

              <Column2>
                <ZoomableContainer imgWidth={imageWidth} imgHeight={imgHeight} screenWidth={screenWidth}>
                  <ZoomableImage src={img} alt={alt} />
                </ZoomableContainer>
              </Column2>
              
            </KylmahoitoRow>
          </KylmahoitoRowWrapper>
        </KylmahoitoWrapper>
      </KylmahoitoContainter>
    </>
  );
}

export default KylmahoitoSection;