import React, { useState, useEffect } from 'react';
import { HeroContainer,HeroBg,HeroContent,HeroH1,HeroP,HeroBtnWrapper,ArrowForward,ArrowRight } from './HeroElements'
import { ButtonA,Button } from '../ButtonElements'
import theme from '../theme/theme';

const HeroSection = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
          const newScreenWidth = window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth;
          setScreenWidth(newScreenWidth);
        };
      
       
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);

    const [hover,setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer>
        <HeroBg>
           
        </HeroBg>
        <HeroContent>
            <HeroH1>Fysioterapeutti lähelläsi</HeroH1>
            <HeroP>
            Fysioterapiapalvelut Ulvilan ja Porin alueilla
            </HeroP>
            <HeroBtnWrapper>
            {screenWidth < 768 ? <ButtonA href="tel:0407574567" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>Soita {hover ? <ArrowForward />: <ArrowRight />}
                </ButtonA>: <></>}
                
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection