import React, {useState} from 'react'
import Footer from '../components/Footer'
import { Kylmahoito} from '../components/kylmahoito/Data'
import Navbar from '../components/NavbarSubPage'
import KylmahoitoSection from '../components/kylmahoito'

const KylmaK = () => {

    const [isOpen,setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle}/>
    <KylmahoitoSection  {...Kylmahoito}/>
    <Footer/>
  
    </>
  )
}

export default KylmaK