import styled from "styled-components";

import {MdKeyboardArrowRight,MdArrowForward} from 'react-icons/md'

import taustakuva from '../../images/webp2/minna2.png';

import theme from '../theme/theme';

export const HeroContainer = styled.div`


display: flex;
padding: 0 30px;
height: 800px;
position: relative;

margin: 0 auto;
justify-content: center;
align-items: center;
max-width: ${theme.page.maxWidth}};

background-image: url(${taustakuva});
ackground-size: 100% auto; /* Taustakuvan korkeus on divin korkeus */
background-position: left bottom;
background-repeat: no-repeat; /* Estä kuvan toisto */




:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 250, 255, 0.2); /* Tässä asetat halutun läpinäkyvyyden ja värin */   
}
`;

export const HeroBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
`;

export const VideoBg = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;

`;

export const HeroContent = styled.div`

z-index: 3;
max-width: 1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction: column;
align-items: center;

@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 32px;
}
`;

export const HeroP = styled.p`
margin-top: 24px;
color:#00995B;
font-size: 24px;
text-align: center;
max-width: 600px;

@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`;

export const HeroH1 = styled.h1`
margin-top: 100px;
color: #004D2E;
font-size: 48px;
text-align: center;

@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 35px;
}
`

export const HeroBtnWrapper = styled.div`
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
margin-left: 8px;
font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`;

