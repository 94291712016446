import React,{useState,useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav,NavbarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink,NavLinkSubPage} from './NavbarElements'
import {animateScroll as scroll} from 'react-scroll'; 

const Navbar = ({toggle}) => {
    const [scrollNav,setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect (() => {
        window.addEventListener('scroll',changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }
  return (
   <>
   <Nav scrollNav={scrollNav}>
    <NavbarContainer>
        <NavLogo to='/' onClick={toggleHome}>Minna Repo</NavLogo>
        <MobileIcon onClick={toggle}>
            <FaBars />
        </MobileIcon>
        <NavMenu>
            <NavItem>
                <NavLinkSubPage to='/kotikaynnit' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Kotikäynnit</NavLinkSubPage>
            </NavItem>
            <NavItem>
                <NavLinkSubPage to='/huippukylmahoito' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Huippukylmähoito</NavLinkSubPage>
            </NavItem>
            <NavItem>
                <NavLinks to='palvelut' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Palvelut</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='maksutavat' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Maksutavat</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='yhteystiedot' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Yhteystiedot</NavLinks>
            </NavItem>
        </NavMenu>
    </NavbarContainer>
   </Nav>
   </>
  )
}

export default Navbar
