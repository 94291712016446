import React, { useState, useEffect } from 'react';

import {  Column1, Column2, Heading, ZoomableContainer, ZoomableImage, InfoContainter, InfoRow,InfoRowWrapper, InfoWrapper, Subtitle, TextWrapper, TopLine } from './InfoElements'
import theme from '../theme/theme';

const InfoSection = ({ bgcolor,bgcolorMobile,dark,darkMobile, id, imgStart, topLine, headLine, description, img, alt, imgWidth, imgHeight,lightText }) => {

  

  const [screenWidth, setScreenWidth] = useState(window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth);
  const [textWidth, setTextWidth] = useState(`${screenWidth - (screenWidth * imgWidth)}px`);
  const [imageWidth, setImageWidth] = useState(`${screenWidth * imgWidth}px`);

  useEffect(() => {

  const handleResize = () => {
    const newScreenWidth = window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth;
    setScreenWidth(newScreenWidth);
    setTextWidth(`${newScreenWidth - (newScreenWidth * imgWidth)}px`);
    setImageWidth(`${newScreenWidth * imgWidth}px`);
  };

 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <InfoContainter bgcolor={bgcolor} bgcolorMobile={bgcolorMobile} id={id} screenWidth={screenWidth}>
        <InfoWrapper screenWidth={screenWidth}>
          <InfoRowWrapper bgcolor={bgcolor} bgcolorMobile={bgcolorMobile}>
            <InfoRow imgStart={imgStart}>
              <Column1 imgWidth={imgWidth}>
                <TextWrapper imgWidth={imgWidth} textWidth={textWidth}>
                  <TopLine>{topLine}</TopLine>
                  <Heading  dark={dark} darkMobile={darkMobile}>{headLine}</Heading>
                  <Subtitle dark={dark} darkMobile={darkMobile} lightText={lightText}>{description}</Subtitle>
                </TextWrapper>
              </Column1>
              <Column2>
                <ZoomableContainer imgWidth={imageWidth} imgHeight={imgHeight} screenWidth={screenWidth}>
                  <ZoomableImage src={img} alt={alt} />
                </ZoomableContainer>
              </Column2>
            </InfoRow>
          </InfoRowWrapper>
        </InfoWrapper>
      </InfoContainter>
    </>
  );
}

export default InfoSection;