import React, {useState} from 'react'
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne, homeObjTree, homeObjTwo, homeObjMaksutavat,homeObjYhteystiedot } from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/sidebar'

const Home = () => {

    const [isOpen,setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}/>
    <HeroSection />
    <InfoSection  {...homeObjOne}/>
    <InfoSection  {...homeObjTwo}/>
    <InfoSection  {...homeObjTree}/>
    <InfoSection  {...homeObjMaksutavat}/>
    <InfoSection  {...homeObjYhteystiedot}/>
    <Footer/>
  
    </>
  )
}

export default Home