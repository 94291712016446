import theme from '../theme/theme';

export const kotikaynnit = {
    bgcolor: `${theme.colors.primary}`,
    bgcolorMobile: `${theme.colors.primary}`,
    dark: false,
    darkMobile: false,
    lightText: false,
    imgStart: false,
    imgWidth:0.1,
    id: 'kotikaynnit',
    topLine: '',
    headLine: 'Kotikäynnit',
    description: `
Fysioterapeutti lähelläsi tekee kotikäyntejä Ulvilan, Nakkilan ja Porin alueilla.
    
Joskus elämäntilanteen kannalta on helpompaa ja tehokkaampaa toteuttaa fysioterapiakäynnit kotona tai hankkia kuntoutusta omaiselle hoitolaitokseen. Kotikäynnit ovat ajankohtaisia esimerkiksi leikkauksen jälkeisessä kuntoutuksessa, ikääntyneen toimintakyvyn ylläpitämisessä ja kotona elämisen tukemisessa tai lapsen sensomotorisen kehityksen tukemisessa.
    
Vuoden 2024 alusta fysioterapeutin tekemät kotikäynnit muuttuivat kotitalousvähennyskelpoisiksi. Vuonna 2024 vähennys on 60% kotikäynnin työn osuudesta, omavastuuosuus on 100 euroa ja enimmäismäärä 3500 euroa henkilöä kohden verovuoden aikana.
    
Kotikäynnin voi varata itselleen tai samassa taloudessa asuvalle puolisolle tai lapselle. Kotitalousvähennyksen voi saada myös täysi-ikäinen henkilö, joka ostaa fysioterapiaa omille tai puolisonsa vanhemmille tai isovanhemmille. Tällöin fysioterapia voidaan toteuttaa kuntoutettavan kodissa, hoivakodissa, palvelutalossa tai vapaa-ajan asunnossa. Tilanteessa, jossa palvelun tilanneen lapsi asuu eri taloudessa kuin palvelun maksava vanhempi, kotitalousvähennystä ei voi tehdä. Lisätietoa kotitalousvähennyksestä saa verottajalta.`,
    
    img: '',
    alt:'auto'
    
};

