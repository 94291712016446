import styled from "styled-components";

    

export const InfoContainter = styled.div`
    color: #fff;
    background: ${({ bgcolor }) => (bgcolor  ? bgcolor : '#fff')};
     

    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: ${({screenWidth}) => (screenWidth ? `${screenWidth}px` : '100%')};

    @media screen and (max-width: 900px){
        background: ${({ bgcolorMobile }) => (bgcolorMobile  ? bgcolorMobile : '#fff')};
    }
    
`;

export const InfoWrapper = styled.div`

    z-index: 1;
    display: flex;
    height: 500px;
    width: 100%;

    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: ${({screenWidth}) => (screenWidth ? `${screenWidth}px` : '100%')};

    @media screen and (max-width: 900px){
        height: 900px;
      }
`;

export const InfoRowWrapper = styled.div`
    display: flex;
    height: 500px;
    width: 100%;
    justify-content: ${({bgcolor}) => (bgcolor ? 'flex-end' : 'flex-start')};

    @media screen and (max-width: 900px){
        height: 900px;
      }
`;

export const InfoRow = styled.div`
    
    width:100%;
    display: grid;
    grid-auto-columns: minmax(auto-fill,1fr);
    grid-template-rows: 1fr;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};


    @media screen and (max-width: 900px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
    }

`;

export const Column1 = styled.div`
    width: flex;
    grid-area: col1;
    align-self: center;
    
`;

export const Column2 = styled.div`
    width: flex;
    grid-area: col2;
    align-self: center;
`;


export const TextWrapper = styled.div`
width: ${({ textWidth }) => textWidth  ? textWidth: '40%'};
padding-left:50px;
padding-top: 0;
padding-bottom: 60px;

@media screen and (max-width: 900px){
    width: 100%;
}

`;

export const TopLine = styled.p`
color: #004D2E;
font-size: 16px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`;


export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: ${({dark}) => (dark ? '#f7f8fa' : '#010606')};

@media screen and (max-width: 900px){
    color: ${({darkMobile}) => (darkMobile ? '#f7f8fa' : '#010606')};
  }

  @media screen and (max-width: 900px){
    font-size: 40px;
}

@media screen and (max-width: 768px) {
    font-size: 35px;
}

@media screen and (max-width: 480px) {
    font-size: 30px;
}

`;

export const Subtitle=styled.text`
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
white-space: pre-wrap;
color: ${({lightText}) => (lightText ? "#fff" : '#010606')};

@media screen and (max-width: 900px){
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}

@media screen and (max-width: 480px) {
    font-size: 12px;
}

`;

export const BtnWrap = styled.div `
display: flex;
justify-content: flex-start;
`;

export const ZoomableContainer = styled.div`
  width: ${({ imgWidth }) => (imgWidth  ? imgWidth : '60%')}; 
  height:  ${({imgHeight}) => (imgHeight ? imgHeight : '500px')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 900px){
    width: 100%;
    height: 400px;
  }
`;

export const ZoomableImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


