import React from 'react'
import { FooterContainer, FooterLink, FooterLinkContainer, FooterLinkItems,FooterLinkExternal, FooterLinkTitle, FooterLinkWrapper, FooterWrap,SocialMedia,SocialMediaWrap,SocialIconLink, SocialIcons, SocialLogo, WebsiteRights } from './FooterElements'

import { FaFacebook,FaTwitter,FaInstagram,FaYoutube,FaLinkedin } from 'react-icons/fa'

import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    }

  return (
    <>
    <FooterContainer>
        <FooterWrap>
            <FooterLinkContainer>
                
                <FooterLinkWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Asiakirjat</FooterLinkTitle>
                            <FooterLink to="/Tietosuoja_ja_rekisteriseloste.pdf" target="_blank" download>Tietosuoja- ja rekisteriseloste</FooterLink>
                            <FooterLink to="/Omavalvontasuunnitelma_2024.pdf" target="_blank" download>Omavalvontasuunnitelma</FooterLink>
                    </FooterLinkItems>
                </FooterLinkWrapper>

                <FooterLinkWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Y-tunnus</FooterLinkTitle>
                            <FooterLinkExternal href="https://tietopalvelu.ytj.fi/yritys/2855583-3">2855583-3</FooterLinkExternal>
                    </FooterLinkItems>
                </FooterLinkWrapper>

            </FooterLinkContainer>
            <SocialMedia>
            <SocialMediaWrap>
                <SocialLogo to ='/' onClick={toggleHome}>Minna Repo</SocialLogo>
                <WebsiteRights> Minna Repo © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                <SocialIcons>
                    <SocialIconLink href="https://www.facebook.com/fysioterapeuttiMinnaRepo"  aria-label="Facebook">
                       <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="https://www.instagram.com/minnarepotmi/"  aria-label="Instagram">
                       <FaInstagram />
                    </SocialIconLink>
                </SocialIcons>
            </SocialMediaWrap>
            </SocialMedia>

        </FooterWrap>
    </FooterContainer>
    </>
  )
}

export default Footer