const theme = {
    page:{
        maxWidth:'1700px',
        maxWidthNoPx:'1700'
    },
    colors: {
      primary: '#F0FAFF',
      secondary: '#00E689',
      background: '#91ABC2',
      text: '#333',
    },
    spacing: {
      small: '8px',
      medium: '16px',
      large: '24px',
    },
  };
  
  export default theme;