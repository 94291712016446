import React from 'react'


import { SidebarContainer,Icon,CloseIcon,SidebarBtnWrap,SidebarLink,SidebarRoute,SidebarWrapper,SidebarMenu } from './SidebarElements'



const Sidebar = ({isOpen,toggle}) => {
  return (
   <>
   <SidebarContainer isOpen={isOpen} onClick={toggle}>
    <Icon onClick={toggle}>
        <CloseIcon />
    </Icon>
    <SidebarWrapper>
        <SidebarMenu>
            <SidebarRoute to="/kotikaynnit" onClick={toggle}>
            Kotikäynnit
            </SidebarRoute>
            <SidebarRoute to="/huippukylmahoito" onClick={toggle}>
            Huippukylmähoito
            </SidebarRoute>
            <SidebarLink to="palvelut" onClick={toggle}>
            Palvelut
            </SidebarLink>
            <SidebarLink to="maksutavat" onClick={toggle}>
            Maksutavat
            </SidebarLink>
            <SidebarLink to="yhteystiedot" onClick={toggle}>
            Yhteystiedot
            </SidebarLink>
        </SidebarMenu>
    </SidebarWrapper>
   </SidebarContainer>
   </>
  )
}

export default Sidebar