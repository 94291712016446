import theme from '../theme/theme';

export const Kylmahoito = {
    bgcolor: `${theme.colors.primary}`,
    bgcolorMobile: `${theme.colors.primary}`,
    dark: false,
    darkMobile: false,
    lightText: false,
    imgStart: false,
    imgWidth:0.4,
    id: 'huippukylmahoito',
    topLine: '',
    headLine: 'Huippukylmähoito',
    description: `X°CRYO™ huippukylmähoidolla voidaan aktivoida kehon omia luonnollisia paranemisprosesseja. Huippukylmä soveltuu erityisesti kiputilojen hoitoon, liikkuvuuden lisäämiseen sekä tulehdusten ja turvotuksen lieventämiseen.

Hoidossa ihon pintakudos jäähdytetään paikallisesti ja nopeasti huippukylmällä ilmalla eli se on turvallinen ja lääkkeetön tapa hoitaa kehon eri osia. Soveltuu myös sydän- ja verisuonitautia sairastaville. Huippukylmä sarjahoitona tai osana fysioterapiaa takaa parhaan lopputuloksen.

    `,
    
    img: require('../../images/webp/XCRYO.webp'),
    alt:'auto'
    
};

