import React, { useState, useEffect } from 'react';

import {  Column1, Column2, Heading, ZoomableContainer, ZoomableImage, KotikaynnitContainter, KotikaynnitRow,KotikaynnitRowWrapper, KotikaynnitWrapper, Subtitle, TextWrapper, TopLine } from './kotikaynnitElements'
import theme from '../theme/theme';


const KotikaynnitSection = ({ bgcolor,bgcolorMobile,dark,darkMobile, id, imgStart, topLine, headLine, description, img, alt, imgWidth, imgHeight,lightText }) => {

  

  const [screenWidth, setScreenWidth] = useState(window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth);
  const [textWidth, setTextWidth] = useState(`${screenWidth - (screenWidth * imgWidth)}px`);
  const [imageWidth, setImageWidth] = useState(`${screenWidth * imgWidth}px`);

  useEffect(() => {

    window.scrollTo(0, 0);

  const handleResize = () => {
    const newScreenWidth = window.innerWidth > theme.page.maxWidthNoPx ? theme.page.maxWidthNoPx : window.innerWidth;
    setScreenWidth(newScreenWidth);
    setTextWidth(`${newScreenWidth - (newScreenWidth * imgWidth)}px`);
    setImageWidth(`${newScreenWidth * imgWidth}px`);
  };

 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <KotikaynnitContainter bgcolor={bgcolor} bgcolorMobile={bgcolorMobile} id={id} screenWidth={screenWidth}>
        <KotikaynnitWrapper screenWidth={screenWidth}>
          <KotikaynnitRowWrapper bgcolor={bgcolor} bgcolorMobile={bgcolorMobile}>
            <KotikaynnitRow imgStart={imgStart}>
              <Column1 imgWidth={imgWidth}>
                <TextWrapper imgWidth={imgWidth} textWidth={textWidth}>
                  <TopLine>{topLine}</TopLine>
                  <Heading  dark={dark} darkMobile={darkMobile}>{headLine}</Heading>
                  <Subtitle dark={dark} darkMobile={darkMobile} lightText={lightText}>{description}</Subtitle>
                </TextWrapper>
              </Column1>
              
            </KotikaynnitRow>
          </KotikaynnitRowWrapper>
        </KotikaynnitWrapper>
      </KotikaynnitContainter>
    </>
  );
}

export default KotikaynnitSection;